<template>
    <footer class="footer">
      <p>&copy; 2024 Raipurian Plumbing Solutions. All Rights Reserved.</p>
    </footer>
  </template>
  
  <style>
  .footer {
    background: #333;
    color: white;
    text-align: center;
    padding: 1rem;
  }
  </style>
  