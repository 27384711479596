<template>
    <section class="faq">
      <h2>Frequently Asked Questions</h2>
      <div class="faq-container">
        <div 
          class="faq-item" 
          v-for="(item, index) in faqs" 
          :key="index"
        >
          <div 
            class="faq-question" 
            @click="toggleFAQ(index)"
          >
            <span>{{ item.question }}</span>
            <span>{{ activeIndex === index ? '-' : '+' }}</span>
          </div>
          <div 
            class="faq-answer" 
            v-if="activeIndex === index"
          >
            <p>{{ item.answer }}</p>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "FAQ",
    data() {
      return {
        activeIndex: null,
        faqs: [
  {
    question: "What services do you provide?",
    answer: "We offer a wide range of plumbing services, including pipe repair, drain cleaning, leak detection, and water heater installation.",
  },
  {
    question: "Are your services available 24/7?",
    answer: "Yes, our emergency plumbing services are available 24/7 to assist you with any urgent needs.",
  },
  {
    question: "How can I contact you?",
    answer: "You can reach us through our Contact Us section, via WhatsApp, or by calling directly.",
  },
  {
    question: "Do you provide free estimates?",
    answer: "Yes, we provide free estimates for all our services. Please get in touch for more details.",
  },
  {
    question: "What types of pipes do you work with?",
    answer: "We work with all types of plumbing pipes, including copper, PVC, PEX, and galvanized steel pipes.",
  },
  {
    question: "How do I know if I have a leak?",
    answer: "Signs of a leak include water stains, a sudden increase in your water bill, or hearing water running when no taps are open.",
  },
  {
    question: "Can you help with a blocked drain?",
    answer: "Yes, we specialize in drain cleaning and can fix blocked drains quickly and efficiently.",
  },
  {
    question: "Do you offer maintenance services?",
    answer: "Yes, we offer regular plumbing maintenance services to ensure that your plumbing system works smoothly and prevent costly repairs.",
  },
  {
    question: "How long does it take to fix a leak?",
    answer: "The time it takes to fix a leak depends on its severity and location, but most leaks can be repaired within a few hours.",
  },
  {
    question: "Do you offer water heater installation?",
    answer: "Yes, we offer water heater installation, as well as repair and replacement of all types of water heaters.",
  },
  // Additional 7 FAQs:
  {
    question: "What is hydro jetting and when should it be used?",
    answer: "Hydro jetting is a method that uses high-pressure water to clear stubborn blockages in pipes. It's effective for clearing grease, tree roots, and other tough clogs.",
  },
  {
    question: "What should I do if my toilet is clogged?",
    answer: "If your toilet is clogged, try using a plunger first. If that doesn’t work, give us a call for professional assistance to avoid damaging the plumbing.",
  },
  {
    question: "How can I prevent my pipes from freezing in winter?",
    answer: "To prevent pipes from freezing, insulate exposed pipes, keep your home warm, and allow faucets to drip slightly to keep the water flowing.",
  },
  {
    question: "Do you offer emergency plumbing services?",
    answer: "Yes, we offer 24/7 emergency plumbing services, including pipe bursts, sewer backups, and other urgent plumbing issues.",
  },
  {
    question: "What causes low water pressure in my home?",
    answer: "Low water pressure can be caused by clogs, leaks, or problems with the main water supply. Our team can diagnose and fix the issue quickly.",
  },
  {
    question: "Can you install water filtration systems?",
    answer: "Yes, we install water filtration systems to improve water quality in your home, whether it's for drinking water or whole-house filtration.",
  },
  {
    question: "How do I maintain my sump pump?",
    answer: "Regularly check the sump pump to ensure it's free from debris, test it to ensure it works, and ensure the discharge line is clear to prevent flooding.",
  },
  {
    question: "How often should I replace my water heater?",
    answer: "Water heaters typically last 10-15 years. If your unit is aging or experiencing issues like inconsistent hot water, it may be time for a replacement.",
  },
]

      };
    },
    methods: {
      toggleFAQ(index) {
        this.activeIndex = this.activeIndex === index ? null : index;
      },
    },
  };
  </script>
  
  <style scoped>
  /* FAQ Section */
  .faq {
    padding: 2rem;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .faq h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 2rem;
  }
  
  /* FAQ Container */
  .faq-container {
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
  }
  
  /* FAQ Item */
  .faq-item {
    margin-bottom: 1rem;
    border-bottom: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
  }
  
  .faq-item:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* FAQ Question */
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #fff;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    transition: background-color 0.3s ease;
  }
  
  .faq-question:hover {
    background-color: #f0f0f0;
  }
  
  /* FAQ Answer */
  .faq-answer {
    padding: 1rem;
    background-color: #fefefe;
    font-size: 1rem;
    color: #555;
    border-top: 1px solid #ddd;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .faq h2 {
      font-size: 1.8rem;
    }
  
    .faq-question {
      font-size: 1rem;
    }
  
    .faq-answer {
      font-size: 0.9rem;
    }
  }
  </style>
  