<template>
    <section class="gallery">
      <h2>Our Gallery</h2>
      <div class="masonry-grid">
        <div 
          class="gallery-item" 
          v-for="(image, index) in images" 
          :key="index"
          :class="getItemClass(index)"
        >
          <img 
            :src="image.src" 
            :alt="image.alt" 
            @click="openLightbox(index)"
          />
        </div>
      </div>
  
      <!-- Lightbox Overlay -->
      <div 
        class="lightbox" 
        v-if="lightboxActive" 
        @click="closeLightbox"
      >
        <img :src="images[activeImage].src" :alt="images[activeImage].alt" />
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "GallerySection",
    data() {
      return {
        images: [
           { src: require("@/assets/gallery/rcc-boundary-wall.webp"), alt: "Gallery Image 1" },
      { src: require("@/assets/gallery/1.webp"), alt: "Gallery Image 2" },
      { src: require("@/assets/gallery/2.jpg"), alt: "Gallery Image 3" },
      { src: require("@/assets/gallery/images.webp"), alt: "Gallery Image 4" },
      { src: require("@/assets/gallery/16_Melodie_V1_02__FLAT__635px.webp"), alt: "Gallery Image 5" },
      { src: require("@/assets/gallery/type-of-a-toilet.webp"), alt: "Gallery Image 6" },
        ],
        lightboxActive: false,
        activeImage: null,
      };
    },
    methods: {
      openLightbox(index) {
        this.activeImage = index;
        this.lightboxActive = true;
      },
      closeLightbox() {
        this.lightboxActive = false;
        this.activeImage = null;
      },
      getItemClass(index) {
        return index % 3 === 0 ? "tall" : index % 5 === 0 ? "wide" : "";
      },
    },
  };
  </script>
  
  <style scoped>
  /* Gallery Section */
  .gallery {
    padding: 2rem;
    text-align: center;
    background-color: #f9f9f9;
  }
  
  .gallery h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1.5rem;
  }
  
  /* Masonry Grid */
  .masonry-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-rows: 150px;
    gap: 1rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .gallery-item {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* Unique Sizes */
  .gallery-item.tall {
    grid-row: span 2;
  }
  
  .gallery-item.wide {
    grid-column: span 2;
  }
  
  .gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  
  .gallery-item:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  /* Lightbox */
  .lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .lightbox img {
    max-width: 90%;
    max-height: 90%;
    border-radius: 10px;
  }
  
  .lightbox:hover {
    cursor: pointer;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .gallery h2 {
      font-size: 1.8rem;
    }
  
    .masonry-grid {
      gap: 0.5rem;
    }
  }
  </style>
  