<template>
  <section class="contact">
    <h2>Contact Us</h2>
    <p>We are here to help. Reach out to us anytime!</p>
    <div class="contact-options">
      <!-- WhatsApp Contact -->
      <div class="contact-option">
        <img src="@/assets/images/icon/WhatsApp.png" style="width:60px;height: 60;" alt="WhatsApp" />
        <a 
          href="https://wa.me/+917898188334" 
          target="_blank" 
          rel="noopener noreferrer"
          class="contact-link"
        >
          Chat on WhatsApp
        </a>
      </div>

      <!-- Call Contact -->
      <div class="contact-option">
        <img src="@/assets/images/icon/accept-call-icon.webp" alt="Phone" />
        <a href="tel:+917898188334" class="contact-link">Call Us</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactSection",
};
</script>

<style scoped>
/* Contact Section Styling */
.contact {
  padding: 2rem;
  background-color: #f3f4f6;
  text-align: center;
}

.contact h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
}

.contact p {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 2rem;
}

/* Contact Options */
.contact-options {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.contact-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
}

.contact-option img {
  width: 50px;
  height: 50px;
  transition: transform 0.3s ease-in-out; /* Smooth zoom effect */
  object-fit: cover; /* Ensure images maintain their aspect ratio */
}

.contact-option img:hover {
  transform: scale(1.2); /* Zoom in the image */
}


.contact-link {
  font-size: 1.1rem;
  font-weight: bold;
  color: #0077cc;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-link:hover {
  color: #005fa3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-options {
    flex-direction: column;
    gap: 1.5rem;
  }
}
</style>
