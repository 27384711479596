<template>
  <div id="app">
    <section id="home">
    <Header />
  </section>
    <!-- Main Content -->
    <main>
      <section id="services">
        <Services />
      </section>
      <section id="about">
        <About />
        <Gallery />
      </section>
      <section id="contact">
        <Contact />
      </section>
      <section id="faq">
        <FAQ />
      </section>
    </main>

    <!-- Footer Section -->
    <Footer />

    <!-- Call Now Button (Only shows when scrolled past the services section) -->
    <div v-if="showCallButton" class="call-button-container">
      <a href="tel:+917898188334" class="call-button">Call Now 7898188334</a>
    </div>
  </div>
</template>

<script>
import Header from './components/AppHeader.vue';
import Services from './components/ServicesSection.vue';
import About from './components/AboutSection.vue';
import Contact from './components/ContactSection.vue';
import FAQ from './components/FAQ.vue';
import Footer from './components/AppFooter.vue';
import Gallery from './components/GallerySection.vue';

export default {
  components: {
    Header,
    Services,
    About,
    Contact,
    FAQ,
    Footer,
    Gallery,
  },
  data() {
    return {
      showCallButton: false, // Initially the button is hidden
    };
  },
  methods: {
    handleScroll() {
      const servicesSection = document.querySelector('#home');
      const servicesHeight = servicesSection.offsetTop + servicesSection.offsetHeight;
      const scrollPosition = window.scrollY + window.innerHeight; // The current scroll position + viewport height

      // Show the button if the user has scrolled past the services section
      if (scrollPosition > servicesHeight) {
        this.showCallButton = true;
      } else {
        this.showCallButton = false;
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style>
/* Global Styles */
@import './assets/styles.css';

/* Call Now Button */
.call-button-container {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  transition: opacity 0.3s ease-in-out;
}

.call-button {
  background-color: #25d366; /* WhatsApp Green Color */
  color: white;
  font-size: 1rem;
  font-weight: 600;
  padding: 10px 24px;
  border-radius: 50px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.call-button:hover {
  background-color: #128c7e; /* Darker green */
  transform: scale(1.05);
}

.call-button:active {
  background-color: #075e54; /* Darker green for active */
}
</style>
