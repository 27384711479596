<template>
  <header class="header">
    <div class="logo">
      <img src="@/assets/name-logo.jpg" alt="Logo" />
    </div>

    <!-- Hamburger Icon for Mobile -->
    <div class="hamburger" @click="toggleMenu">
      <div></div>
      <div></div>
      <div></div>
    </div>

    <!-- Navigation Menu -->
    <nav class="nav-menu" :class="{ 'menu-active': isMenuOpen }">
      <ul>
        <li><a href="#services" @click="closeMenu">Our Services</a></li>
        <li><a href="#about" @click="closeMenu">About</a></li>
        <li><a href="#contact" @click="closeMenu">Contact</a></li>
        <li><a href="#faq" @click="closeMenu">FAQ</a></li>
      </ul>
    </nav>
  </header>
  <section class="hero">
  <div class="hero-content">
    <h1>Welcome to Raipurian Plumbing Solutions</h1>
    <p>Reliable and professional plumbing services tailored to meet your needs.</p>
    <div class="cta-buttons">
      <a href="tel:+917898188334" class="call-button">
        📞 Call Us
      </a>
      <a href="https://wa.me/917898188334" class="whatsapp-button" target="_blank">
        💬 WhatsApp Us
      </a>
    </div>
  </div>
  <div class="hero-image">
    <img src="@/assets/images/icon.webp" alt="Hero Plumbing Image" />
  </div>
</section>

</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      isMenuOpen: false, // State to track if the menu is open
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen; // Toggle the menu
    },
    closeMenu() {
      this.isMenuOpen = false; // Close the menu when clicking a link
    },
  },
};
</script>

<style scoped>
/* Header Styling */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  background-color: #ffffff;
  color: hsl(0, 0%, 24%);
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Logo Section */
.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 55px;
  margin-right: 10px;
}

.logo span {
  font-size: 1.5rem;
  font-weight: bold;
  color: #f9d342;
}

/* Navigation Menu */
.nav-menu {
  display: flex;
}

.nav-menu ul {
  list-style: none;
  display: flex;
  gap: 2rem;
  margin: 0;
  padding: 0;
}

.nav-menu a {
  text-decoration: none;
  color: #444444;
  font-weight: bold;
  transition: color 0.3s ease;
  position: relative; /* Needed for the pseudo-element positioning */
}

.nav-menu a::after {
  content: ''; /* The line is created with this */
  position: absolute;
  left: 0;
  bottom: -3px; /* Distance from the text */
  width: 0%; /* Initially, the line is hidden */
  height: 2px; /* Thickness of the line */
  background-color: #4254f9; /* Line color */
  transition: width 0.3s ease; /* Smooth animation */
}

.nav-menu a:hover {
  color: #4254f9;
}

.nav-menu a:hover::after {
  width: 100%; /* Expands the line on hover */
}

/* Hamburger Icon */
.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 20px;
  cursor: pointer;
}

.hamburger div {
  height: 3px;
  background-color: #444444;
  border-radius: 2px;
}

/* Responsive Styling */
@media (max-width: 768px) {
  /* Hide navigation menu by default on small screens */
  .nav-menu {
    display: none;
    position: absolute;
    top: 70px;
    right: 0;
    background-color: hsl(0, 0%, 100%);
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
    padding: 1.3rem 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  /* Show menu when active */
  .nav-menu.menu-active {
    display: flex;
  }

  /* Show hamburger icon */
  .hamburger {
    display: flex;
  }
}

/* Hero Section Styling */
.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  background-image: url('@/assets/images/heder.avif'); /* Replace with your image path */
  background-size: cover; /* Ensures the image covers the entire header */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents image repetition */
  min-height: 80vh;
  gap: 2rem;
}

/* Hero Content */
.hero-content {
  flex: 1;
}

.hero-content h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

.hero-content p {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 2rem;
}

/* Buttons Container */
.cta-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

/* Generic Button Style */
.cta-button,
.call-button,
.whatsapp-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 50px;
  text-decoration: none;
  transition: transform 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Get a Quote Button */
.cta-button {
  background-color: #4254f9;
  color: white;
  border: none;
}

.cta-button:hover {
  background-color: #3543c9;
  transform: scale(1.05);
}

/* Call Button */
.call-button {
  background-color: #ff5722;
  color: white;
  border: none;
}

.call-button:hover {
  background-color: #e64a19;
  transform: scale(1.05);
}

/* WhatsApp Button */
.whatsapp-button {
  background-color: #25d366;
  color: white;
  border: none;
}

.whatsapp-button:hover {
  background-color: #20b352;
  transform: scale(1.05);
}

/* Button Icons */
.call-button i,
.whatsapp-button i {
  margin-right: 0.5rem;
  font-size: 1.2rem;
}

/* Hero Image */
.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    text-align: center;
  }

  .hero-content {
    margin-bottom: 2rem;
  }

  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .cta-button {
    font-size: 0.9rem;
    padding: 0.7rem 1.2rem;
  }
}
</style>
