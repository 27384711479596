<template>
  <section class="services">
    <h2>Our Services</h2>
    <div class="services-grid">
      <div class="service-card" v-for="service in services" :key="service.name">
        <img :src="service.image" :alt="service.name" />
        <h3>{{ service.name }}</h3>
        <p>{{ service.description }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ServicesSection",
  data() {
    return {
      services: [
  {
    name: "Pipe Repair",
    image: require("@/assets/service/pipe-repair.webp"),
    description: "Expert pipe repair services to fix leaks, bursts, and damages efficiently.",
  },
  {
    name: "Leak Detection",
    image: require("@/assets/service/Leak Detection.webp"),
    description: "Advanced tools to quickly detect and address leaks in your system.",
  },
  {
    name: "Drain Cleaning",
    image: require("@/assets/service/Drain Cleaning.webp"),
    description: "Professional cleaning to keep your drains free of clogs and blockages.",
  },
  {
    name: "Water Heater Installation",
    image: require("@/assets/service/Water Heater Installation.jpg"),
    description: "Reliable water heater installations for both residential and commercial needs.",
  },
  {
    name: "Sewer Line Repair",
    image: require("@/assets/service/Sewer Line Repair.jpg"),
    description: "Comprehensive sewer line repair services to fix blockages and damages.",
  },
  {
    name: "Toilet Repair & Installation",
    image: require("@/assets/service/Toilet Repair & Installation.jpg"),
    description: "Quick and efficient toilet repair and installation services for all types of models.",
  },
  {
    name: "Faucet Repair & Replacement",
    image: require("@/assets/service/Faucet Repair & Replacement.jpg"),
    description: "Repair or replace leaking and malfunctioning faucets with high-quality fixtures.",
  },
  {
    name: "Garbage Disposal Repair",
    image: require("@/assets/service/Garbage Disposal Repair.webp"),
    description: "Repair or replace faulty garbage disposals to restore your kitchen functionality.",
  },
  {
    name: "Shower Repair & Installation",
    image: require("@/assets/service/Shower Repair & Installation.webp"),
    description: "Fix leaking showers or install new ones for an upgraded bathroom experience.",
  },
  {
    name: "Water Filtration Systems",
    image: require("@/assets/service/Water Filtration Systems.jpg"),
    description: "Install and maintain advanced water filtration systems for clean and safe water.",
  },
  {
    name: "Gas Line Repair & Installation",
    image: require("@/assets/service/Gas Line Repair & Installation.webp"),
    description: "Safe and reliable gas line repair and installation for your home or business.",
  },
  {
    name: "Backflow Prevention",
    image: require("@/assets/service/Backflow Prevention.jpg"),
    description: "Prevent contamination of your water supply with professional backflow prevention services.",
  },
]

    };
  },
};
</script>
<style scoped>
/* Services Section */
.services {
  padding: 2rem;
  background-color: #f9f9f9;
  text-align: center;
}

.services h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Grid Layout */
.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  justify-content: center;
  padding: 0 1rem;
}

/* Individual Service Card */
.service-card {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-card img {
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

.service-card h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}

.service-card p {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
}
@media (max-width: 768px) {
  .services {
  padding: 1rem;
}
}
</style>
