<template>
  <section class="about">
    <div class="about-content">
      <h2>About Us</h2>
      <p>
        At Pumbar Plumbing Solutions, we pride ourselves on delivering exceptional plumbing services.
        With years of experience and a commitment to excellence, we ensure that your plumbing needs
        are met with professionalism and expertise.
      </p>
      <div class="contact-info">
      <p><strong>📞 Mobile:</strong> +917898188334</p>
      <p><strong>📞 Mobile:</strong> +917804909235</p>
      <p><strong>📍 Address:</strong> Telibandha Atlantik Tower</p>
      <p><strong>🏙️ City:</strong> Raipur 492001</p>
    </div>
    </div>
    <div class="about-image">
      <img src="@/assets/images/about.png" alt="About Us" />
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutSection",
};
</script>

<style scoped>
/* About Section Styling */
.about {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  background-color: hsl(202, 49%, 83%);
  gap: 2rem;
  min-height: 70vh;
}

/* Content Section */
.about-content {
  flex: 1;
}

.about-content h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

.about-content p {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.contact-info p {
  font-size: 1rem;
  color: #444;
  margin: 0.5rem 0;
}

.contact-info strong {
  color: #000;
}
/* Image Section */
.about-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .about {
    flex-direction: column;
    text-align: center;
  }

  .about-content {
    margin-bottom: 2rem;
  }

  .about-content h2 {
    font-size: 1.8rem;
  }

  .about-content p {
    font-size: 1rem;
  }

  .cta-button {
    font-size: 0.9rem;
    padding: 0.7rem 1.2rem;
  }
}
</style>
